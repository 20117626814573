import React, { createContext, useContext, useEffect, useState } from 'react';
import {AboutData, ChainMetadata, Configuration, PolkawatchApi} from "@polkawatch/ddp-client";
import {getDefaultMetadata} from "../data/ChainDefaults";
import {ThemeColorPresets} from "../components/settings/type";
import useSettings from "../hooks/useSettings";
import {ChainCode} from "../utils/formatSubstrate";


/**
 * Some features may not be enabled in production for certain chains.
 * This normally means that a feature was recognised during indexing but perhaps there is not enough data to introduce
 * it as GA
 * @param meta
 * @constructor
 */
function FeatureRoadmap(meta:ChainMetadata){
    // Adjust features here.
    return meta;
}

/**
 * Chain Setting for static generated content and defaults
 */
let chain= process.env.GATSBY_POLKAWATCH_CHAIN || "polkadot" ;

function BuildApiBasePath (){
    // First workout a default API endpoint detected based on this package version and chain name in current URL
    const apiVersion = "v2";

    if(typeof window !== "undefined"){
        // CHAIN dedicated domains override default.
        // we search patterns because there can be multiple domains, like: kusama, beta-kusama, etc.
        let { hostname } = window.location;
        if(hostname.toUpperCase().search("KUSAMA") >0 ) chain="kusama"
        if(hostname.toUpperCase().search("KUSAMA-ASSET") >0 ) chain="kusama-asset-hub"
    }
    const defaultEndpoint = `https://${chain}-${apiVersion}-api.polkawatch.app`;

    // Development Endpoint Override, used for developing with a local DDP server
    const envPath = process.env.GATSBY_POLKAWATCH_API_URL;

    const basePath = envPath ? envPath : defaultEndpoint;

    return basePath;
}

/**
 * Build the Polkawatch API
 */

const basePath = BuildApiBasePath();
const api = new PolkawatchApi(new Configuration({basePath}));
const checkSeconds = 300;

/**
 * The last time the dataset was updated
 */
let lastDatasetUpdate =0;


interface PolkawatchAPIState {
    api: PolkawatchApi,
    chainMeta: ChainMetadata,
    lastUpdated: number,
    aboutData: AboutData
}

/**
 * The Polkawatch API Context initial state. We include only what we know statically.
 * We want chain meta here, or the UI will start rendering in wrong colors until chain meta is received
 * from the network.
 */
const PolkawatchApiInitialState = {
    api,
    chainMeta: getDefaultMetadata(chain),
    lastUpdated: 0,
    aboutData: {} as AboutData
}

const PolkawatchApiContext = createContext(PolkawatchApiInitialState);

export const usePolkawatchApi = () => {
    return useContext(PolkawatchApiContext);
}

export const PolkawatchApiProvider = ({ children }) => {
    const [state, setState] = useState<PolkawatchAPIState>(PolkawatchApiInitialState);
    const { changeColor } = useSettings();

    /**
     * Initial Load of Chain Metadata details
     */
    useEffect( () => {
        console.log('Retrieving chain metadata');
        api.ddpIpfsAboutChain().then(response => {
            const meta=response.data as ChainMetadata;
            setState(prevState => ({
                ...prevState,
                chainMeta: FeatureRoadmap(meta)
            }))
            // this should never actually happen in production.
            changeColor(ChainCode(meta) as ThemeColorPresets);
        });
    }, [api])

    /**
     * We will check if the dataset has changed every N seconds,
     * A stalled browser session should update everytime a new dataset is made public
     */
    useEffect(()=>{
        const interval = setInterval(function() {
            console.log('Checking for DDP update...');
            api.ddpIpfsAboutDataset({
                lastDays: 60,
                validationType: 'public',
            }).then(response => {
                setState(prevState => {
                    if (response.data.LastUpdated > prevState.lastUpdated)
                        return {
                            ...prevState,
                            aboutData: response.data,
                            lastUpdated: response.data.LastUpdated
                        }
                    else return prevState;
                })
            });
        }, checkSeconds * 1000);
        return () => clearInterval(interval);
    }, [api]);


    return (
        <PolkawatchApiContext.Provider value={state}>
            {children}
        </PolkawatchApiContext.Provider>
    );
}
