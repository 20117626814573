module.exports = [{
      plugin: require('../plugins/gatsby-plugin-top-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../plugins/gatsby-plugin-mui-emotion/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-plausible/gatsby-browser.js'),
      options: {"plugins":[],"domain":"polkawatch.app,polkadot.polkawatch.app","customDomain":"plsb.polkawatch.app/js/plausible.js?original="},
    },{
      plugin: require('../node_modules/gatsby-plugin-mautic/gatsby-browser.js'),
      options: {"plugins":[],"hostUrl":"https://get.polkawatch.app","head":false,"exclude":["/preview/**"],"pageTransitionDelay":0},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
