import {ChainMetadata} from "@polkawatch/ddp-client";

export function AddressDisplay(address){
    if(address) return `${address.slice(0,6)}...${address.slice(-6)}`
    else return "...";
}

export function ChainCode(meta:ChainMetadata){
    // the code, is all lowercase and no spaces derived from the chain name in metadata
    return meta.Name.toLowerCase().split(" ").join("-");
}
