import { alpha } from '@mui/material/styles';

// ----------------------------------------------------------------------

function createGradient(color1: string, color2: string) {
    return `linear-gradient(to bottom, ${color1}, ${color2})`;
}

export type ColorSchema = 'primary' | 'secondary' | 'info' | 'success' | 'warning' | 'error';

interface GradientsPaletteOptions {
  primary: string;
  info: string;
  success: string;
  warning: string;
  error: string;
}

interface ChartPaletteOptions {
  violet: string[];
  blue: string[];
  green: string[];
  yellow: string[];
  red: string[];
}

declare module '@mui/material/styles/createPalette' {
  interface TypeBackground {
    neutral: string;
  }
  interface SimplePaletteColorOptions {
    lighter: string;
    darker: string;
  }
  interface PaletteColor {
    lighter: string;
    darker: string;
  }
  interface Palette {
    gradients: GradientsPaletteOptions;
    chart: ChartPaletteOptions;
  }
  interface PaletteOptions {
    gradients: GradientsPaletteOptions;
    chart: ChartPaletteOptions;
  }
}

declare module '@mui/material' {
  interface Color {
    0: string;
    500_8: string;
    500_12: string;
    500_16: string;
    500_24: string;
    500_32: string;
    500_48: string;
    500_56: string;
    500_80: string;
  }
}

// Polkadot Brand color: E6007A is difficult to use

// SETUP COLORS
const PRIMARY = {
    lighter: '#fbe4ee',
    light: '#ff99cf',
    chart: '#CC006D', // MAIN FOR CHARTING: we prefer a darker option as treemap dominates and has gradients
    main: '#E6007A', // BASE COLOR
    dark: '#7a0043',
    darker: '#660036',
};
const SECONDARY = {
    lighter: '#C9CCCF',
    light: '#9FA3A8',
    main: '#6d7279', // BASE COLOR
    dark: '#575B60',
    darker: '#3A3D40',
};
const INFO = {
    lighter: '#B6AFE9',
    light: '#8A80DB',
    main: '#5F50CE',
    dark: '#4031AF',
    darker: '#2F247F',
};
const SUCCESS = {
    lighter: '#A6CB90',
    light: '#8FBE74',
    main: '#6DA34D',
    dark: '#5C8B41',
    darker: '#41612E',
};
const WARNING = {
    lighter: '#EDE8AB',
    light: '#E2DA78',
    main: '#DBD053',
    dark: '#978E20',
    darker: '#655E15',
};
const ERROR = {
    lighter: '#F68279',
    light: '#F34B3F',
    main: '#C0180C',
    dark: '#99130A',
    darker: '#730F07',
};

const GREY = {
    0: '#FFFFFF',
    100: '#F9FAFB',
    200: '#F4F6F8',
    300: '#DFE3E8',
    400: '#C4CDD5',
    500: '#919EAB',
    600: '#637381',
    700: '#454F5B',
    800: '#212B36',
    900: '#161C24',
    500_8: alpha('#919EAB', 0.08),
    500_12: alpha('#919EAB', 0.12),
    500_16: alpha('#919EAB', 0.16),
    500_24: alpha('#919EAB', 0.24),
    500_32: alpha('#919EAB', 0.32),
    500_48: alpha('#919EAB', 0.48),
    500_56: alpha('#919EAB', 0.56),
    500_80: alpha('#919EAB', 0.8),
};

const GRADIENTS = {
    primary: createGradient(PRIMARY.light, PRIMARY.main),
    info: createGradient(INFO.light, INFO.main),
    success: createGradient(SUCCESS.light, SUCCESS.main),
    warning: createGradient(WARNING.light, WARNING.main),
    error: createGradient(ERROR.light, ERROR.main),
};

const CHART_COLORS = {
    primary: PRIMARY.main,
    violet: ['#7A00CC', '#A51FFF', '#C670FF', '#E7C2FF'], // based on #3c0064
    blue: ['#1543B7', '#245BE5', '#6D91EE', '#A4BBF4'], // based on 0055ff
    yellow: ['#aec000', '#DCF500', '#EDFF47', '#F7FFAD'],
    red: ['#7E848B', '#94999E', '#BFC2C5', '#EAEBEC'], // to gray, based on aec000
    green: ['#00CC8F', '#00F5AB', '#5CFFCE', '#ADFFE7'],
};

const COMMON = {
    common: { black: '#000', white: '#fff' },
    primary: { ...PRIMARY, contrastText: '#fff' },
    secondary: { ...SECONDARY, contrastText: '#fff' },
    info: { ...INFO, contrastText: '#fff' },
    success: { ...SUCCESS, contrastText: GREY[800] },
    warning: { ...WARNING, contrastText: GREY[800] },
    error: { ...ERROR, contrastText: '#fff' },
    grey: GREY,
    gradients: GRADIENTS,
    chart: CHART_COLORS,
    divider: GREY[500_24],
    action: {
        hover: GREY[500_8],
        selected: GREY[500_16],
        disabled: GREY[500_80],
        disabledBackground: GREY[500_24],
        focus: GREY[500_24],
        hoverOpacity: 0.08,
        disabledOpacity: 0.48,
    },
};

const palette = {
    light: {
        ...COMMON,
        mode: 'light',
        text: { primary: '#1e1e1e', secondary: '#6e6e6e', disabled: '#678877' },
        background: { paper: '#ffffff', default: '#f5f3f1', neutral: '#ffffff' },
        action: { active: '#29594B', ...COMMON.action },
    },
    dark: {
        ...COMMON,
        mode: 'dark',
        text: { primary: '#EEEEEF', secondary: '#AEB0B4', disabled: '#AEB0B4' },
        background: { paper: '#222B45', default: '#151a30', neutral: '#222B45' },
        action: { active: '#857784', ...COMMON.action },
    },
};

export default palette;
