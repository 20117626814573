import * as React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import ThemeProvider from '../../src/theme';

// scroll bar
import 'simplebar/src/simplebar.css';

// lazy image
import 'react-lazy-load-image-component/src/effects/blur.css';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import 'react-lazy-load-image-component/src/effects/black-and-white.css';


// contexts
import { SettingsProvider } from '../../src/contexts/SettingsContext';
import { CollapseDrawerProvider } from '../../src/contexts/CollapseDrawerContext';
import { MultiWalletProvider } from '../../src/contexts/MultiWalletContext'
import { PolkawatchApiProvider } from "../../src/contexts/PolkawatchAPIConext";


// components
import RtlLayout from '../../src/components/RtlLayout';
import { ProgressBarStyle } from '../../src/components/ProgressBar';
import ThemeColorPresets from '../../src/components/ThemeColorPresets';
import MotionLazyContainer from '../../src/components/animate/MotionLazyContainer';

// slick-carousel
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import {GlobalStyles} from "@mui/material";


export default function TopLayout(props) {
  return (
    <React.Fragment>
      <GlobalStyles styles={{
          "::-webkit-scrollbar":{width:"5px"},
          "::-webkit-scrollbar-track":{background: "transparent"},
          "::-webkit-scrollbar-thumb":{backgroundColor: "rgba(155, 155, 155, 0.5)", borderRadius: "20px", border:"transparent"}
      }} />
      <Helmet>
        <meta charSet="utf-8"/>
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no"/>

        // Using Google Font
        <link rel="preconnect" href="https://fonts.gstatic.com"/>
        <link
            href="https://fonts.googleapis.com/css2?family=Public+Sans:wght@400;500;600;700&display=swap"
            rel="stylesheet"/>

        // Using Local Font
        <link rel="stylesheet" type="text/css" href="/fonts/index.css"/>

      </Helmet>
        <SettingsProvider>
            <CollapseDrawerProvider>
                <ThemeProvider>
                    <ThemeColorPresets>
                      <PolkawatchApiProvider>
                          <MultiWalletProvider>
                            <RtlLayout>
                                <MotionLazyContainer>
                                    <ProgressBarStyle />
                                    {props.children}
                                </MotionLazyContainer>
                            </RtlLayout>
                          </MultiWalletProvider>
                      </PolkawatchApiProvider>
                    </ThemeColorPresets>
                </ThemeProvider>
            </CollapseDrawerProvider>
        </SettingsProvider>
    </React.Fragment>
  );
}

TopLayout.propTypes = {
  children: PropTypes.node,
};
