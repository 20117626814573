import * as React from "react";
import {ExtensionConfig} from "@polkadotcloud/community/types";
import {createContext, useContext, useEffect, useState} from "react";
import useLocalStorage from "../hooks/useLocalStorage";
import {InjectedAccount, InjectedWindow, InjectedWindowProvider} from "@polkadot/extension-inject/types";
import {usePolkawatchApi} from "./PolkawatchAPIConext";
import {navigate} from "gatsby";

// Conditionally loading the Extension medatata as window is not defined during SSR
const Extensions = typeof window !== `undefined` ? require("@polkadotcloud/community/extensions").Extensions : {}
const { decodeAddress, encodeAddress } = require('@polkadot/util-crypto');

/**
 * The main State of the multiwallet, will either be connected or disconnected
 */
export enum MultiWalletAccountState {
    NO_ACCOUNT_SELECTED,
    ACCOUNT_SELECTED
}

/**
 * Extension States.
 */

export enum ExtensionConnectionState {
    KNOWN,
    DETECTED,
    CONNECTED,
    NOT_AUTHORIZED,
    ERROR
}

/**
 * The Extensions we Know
 */

const KnownExtensions:Record<string, ExtensionState> = (()=>{
    const extensions=Object.keys(Extensions);
    const knownExtensions= {...Extensions};
    // We just inform that we actually know these extensions
    extensions.forEach(e => knownExtensions[e].connectionState=ExtensionConnectionState.KNOWN);
    return knownExtensions;
})();

/**
 * MultiWalletAccount
 */

export interface MultiWalletAccount extends InjectedAccount{
    extension: string,
    recentlyUsed?:boolean
}

/**
 * This is what we know about each extension (wallet) it is a blend of
 * metadata and status which includes a selected account and available accounts
 */
export interface ExtensionState extends ExtensionConfig {
    // The connection state
    connectionState?: ExtensionConnectionState;
    // whether the extension was injected and its injected object
    injected?: InjectedWindowProvider;
    // Accounts Available
    accounts?: Array<MultiWalletAccount>;
}

/**
 * This context will provide this State Object, with everything we know about wallets and the
 * user selection
 */
export class MultiWalletState {
    accountState: MultiWalletAccountState;

    connectedAccount: MultiWalletAccount | undefined;

    extensionsState: Record<string, ExtensionState>;

    extensionAccounts: Array<MultiWalletAccount>;

    onAccountSelected: any;

    onExtensionSelected: any;

    onAccountAnalysed: any;
}


/**
 * We assumed we are disconnected in starting state,
 * We also assume we know nothing about extensions status,
 * only its metadata.
 */
const multiWalletInitialState: MultiWalletState = {
    accountState: MultiWalletAccountState.NO_ACCOUNT_SELECTED,
    connectedAccount: undefined,
    extensionsState: KnownExtensions,
    extensionAccounts: [],
    onAccountSelected: ()=>{},
    onExtensionSelected: ()=>{},
    onAccountAnalysed: () => {}

}

export const MultiWalletContext = createContext(multiWalletInitialState);

export const useMultiWalletContext = () => useContext(MultiWalletContext);

/**
 * This provider is responsible for gathering extension information so that the UI can react to
 * changes in this context.
 * @param children
 * @constructor
 */
export const MultiWalletProvider = ({ children } ) => {

    // Question, how do we know if we are authorized or not? // We can save it if we know where are.
    // Do we need multiple extensions active at the same time? why not?

    // We need to save the extensions we know we are connected to
    const [connectedExtensions,setConnectedExtensions] = useLocalStorage<Array<string>>('pwConnectedExtensions',[]);

    // We need to save the last connected Account
    const [connectedAccount,setConnectedAccount] = useLocalStorage<MultiWalletAccount>('pwConnectedAccount',undefined);

    // We also store the recently used accounts, only those that were successfuly analysed
    const [analysedAccounts,setAnalysedAccounts] = useLocalStorage<Array<MultiWalletAccount>>('pwAnalysedAccounts',[]);

    // Get chain metadata
    const { chainMeta } = usePolkawatchApi();

    const [state,setState]=useState<MultiWalletState>({
        ...multiWalletInitialState,
        onExtensionSelected: handleExtensionSelected,
        onAccountSelected: handleAccountSelected,
        onAccountAnalysed: handleAccountAnalysed
    });


    // Handle the selection of an Account
    async function handleAccountSelected(acc:MultiWalletAccount){
        setConnectedAccount(acc);
        setState((prevState)=>({
            ...prevState,
            connectedAccount: acc,
            accountState: MultiWalletAccountState.ACCOUNT_SELECTED
        }));
        // We move to the nomination section if not already there
        navigate("/nomination");
    }

    async function handleExtensionSelected(key:string, est:ExtensionState)
    {
        switch (est.connectionState){
            case ExtensionConnectionState.DETECTED:
                return connectExtension(key,est);
            case ExtensionConnectionState.KNOWN:
                return (window? window.open(`https://${est.website}`,'_blank'):false);
        }
    }

    async function handleAccountAnalysed(address:string){
        // add the account to the list if it does not exist already
        if(!analysedAccounts.includes(address)) {
            setAnalysedAccounts((prevAnalysedAccounts) => [address, ...prevAnalysedAccounts]);
        }
    }

    useEffect(()=>{
        // Update State when Analysed Account Change
        setState((prevState)=>({
            ...prevState,
            extensionAccounts: [...prevState.extensionAccounts].map(a => ({
                ...a,
                recentlyUsed: analysedAccounts.includes(a.address) ? true : a.recentlyUsed
             }))
        }));
    },[analysedAccounts])

    async function connectExtension(key:string, est:ExtensionState){
        if(!est.injected) {
            console.warn(`Connected extension ${key} has been disabled/uninstalled`);
            return;
        }
        const {enable} = est.injected;
        const newAccounts = [];
        try {
            const r = await enable('Polkawatch');
            r.accounts.subscribe((accs)=>{
                accs.forEach((a) => {
                    const account = a as MultiWalletAccount;
                    // ensure that the address is in the format of the selected account
                    // TODO: why is it returned as a string?
                    // @ts-ignore
                    account.address=encodeAddress(decodeAddress(account.address),parseInt(chainMeta.ID))
                    account.extension=key;
                    if(analysedAccounts.indexOf(account.address)>=0) account.recentlyUsed=true;
                    newAccounts.push(account);
                });
                state.extensionsState[key].connectionState=ExtensionConnectionState.CONNECTED;
                // We set the state to new account list, we avoid duplicates,
                // in case the subscription callback is called several times
                // We also take first the recently received accounts.
                setState((prevState)=>({
                    ...prevState,
                    extensionAccounts:[...newAccounts, ...prevState.extensionAccounts].reduce((accs,a)=>{
                        if(!accs.some(obj=> obj.address === a.address)){
                            accs.push(a)
                        }
                        return accs;
                    },[])
                }));
                // register the extension as connected, on first connection
                if (connectedExtensions.indexOf(key) == -1) setConnectedExtensions([...connectedExtensions,key]);
            });

        }
        catch (e) {
            console.warn(`Could not enable extension ${est.title}: ${e.message}`);
            let nst=ExtensionConnectionState.ERROR;
            if(e.message.toLowerCase().search('authori')>=0) nst=ExtensionConnectionState.NOT_AUTHORIZED;
            state.extensionsState[key].connectionState=nst;
            setState({...state})
            setConnectedExtensions(connectedExtensions.filter(e => e!==key));
        }
    }

    //
    // Wait for web3 api to initialize and provide extension information
    //
    useEffect(() => {
        // This may take time to happen
        const web3CheckInterval=setInterval(()=>{
            const injectedWeb3:InjectedWindow = (window as any)?.injectedWeb3 || null;
            if(injectedWeb3){
                // Check which of the known extensions has been injected, ignore the rest
                const knownExtensions = Object.keys(state.extensionsState);
                const extensionsWithInjectedInformation = state.extensionsState;
                knownExtensions.forEach(e => {
                    if (injectedWeb3[e]){
                        extensionsWithInjectedInformation[e].injected=injectedWeb3[e];
                        extensionsWithInjectedInformation[e].connectionState=ExtensionConnectionState.DETECTED;
                    }
                })
                clearInterval(web3CheckInterval);
                // Update the state
                setState((prevState)=>({
                    ...prevState,
                    connectedAccount: connectedAccount
                }));
                // trigger connection of accounts:
                connectedExtensions.forEach(e => {
                    connectExtension(e, extensionsWithInjectedInformation[e])
                });
            }
        }, 500);
        return () => {
            clearInterval(web3CheckInterval)
        };
    }, [chainMeta]);

    return (
        <MultiWalletContext.Provider value={state}>
            {children}
        </MultiWalletContext.Provider>
    );


};
